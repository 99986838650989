import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderTerms from '../components/headerTerms';

const Terms = () => {
  return (
    <Layout>
      <SEO title="Gyn&eacute;cologie - Obst&eacute;trique - OBGYN - Sant&eacute; - Haiti - Contact" />
      <HeaderTerms />
      <div className="pages-body">
        <div className="terms-contain color-1-text">
        <h5>OBJET </h5>
        <p>
          Tout utilisateur qui accède aux services proposés par Allô Doc s’engage à respecter, sans réserve, les présentes CU. Si l’utilisateur n’est pas en accord avec tout ou en partie des conditions, il lui est vivement recommandé de ne pas utiliser le site. 
          L’utilisation des services est réservée aux utilisateurs personnes physiques capables de souscrire des obligations conformément à la loi haïtienne. 
          L’utilisateur peut utiliser les services pour le compte de tiers sur lesquels il dispose de l’autorité parentale ou pour lesquels il est reconnu tuteur ou curateur conformément au droit haïtien. 
        </p>

        <h5>INFORMATIONS, RESPONSABILITÉS ET GARANTIES RELATIVES AUX MOYENS D'ACCÈS AUX SERVICES</h5>
        <p>
          Allô Doc met en place les moyens nécessaires à la bonne marche des Services. Allô Doc prend les mesures nécessaires au maintien de la continuité et de la qualité des services.
          L’utilisateur reconnaît que son utilisation des services se fait à ses risques et périls. Allô Doc s’efforcera de rendre les services accessibles du Lundi – Dimanche de 7h30 – 9h30 AM puis de 7h – 11h PM, sauf en cas de force majeure ou d’un évènement hors du contrôle de Allô Doc et sous réserve des aléas techniques liés à la nature du réseau internet ou toute atteinte portée au matériel ou aux logiciels de Allô Doc. 
          Allô Doc ne peut être tenu responsable des perturbations du réseau Internet dues à des cas de force majeure. Allô Doc ne peut également pas être tenu responsable de l’installation et du fonctionnement des terminaux utilisés par l’Utilisateur pour accéder aux services et non fournis par Allô Doc.
          L’utilisateur reconnaît que la vitesse de transmission des informations ne dépend pas des services offerts par Allô Doc, mais des caractéristiques inhérentes aux réseaux de communications électronique et des caractéristiques techniques de son mode de connexion (câble, 3G, 4G etc.) et de son accès internet.
          Par ailleurs, l’utilisateur reconnaît que le site renvoie à des prestations de services payantes. Suite à une réservation effectuée par le biais du service de prise de rendez-vous en ligne du Site, l’utilisateur reconnaît et accepte que la prestation de santé effectuée par le professionnel de santé inscrit sur le site soit payante.
        </p>
        <h5>ACCÈS AU SERVICE DE RENDEZ-VOUS EN LIGNE</h5>
        <p>
          Après demande de création du compte de l’utilisateur, ses identifiants sont placés sous la responsabilité exclusive de l’utilisateur. Ce dernier s’oblige à les conserver secrets et à ne pas les divulguer sous quelque forme que ce soit. Si l’un des éléments d’identification de l’utilisateur est perdu ou volé, ce dernier doit informer sans délai Allô Doc, qui procédera alors à l’annulation et/ou la mise à jour immédiate(s) de l’identifiant concerné.
          L’utilisateur est seul responsable de l’utilisation qui est faite de ses identifiants, même si l’utilisateur préenregistre sur son ordinateur, son téléphone mobile ou sur n’importe quel autre type d’équipement son identifiant, permettant ainsi la connexion automatique aux services.
          Tout accès, utilisation des services et transmission de données effectués à partir du compte utilisateur d’un utilisateur sera réputé avoir été effectué par ce dernier. La sauvegarde de la confidentialité des identifiants de l’utilisateur relève de l’entière responsabilité de ce dernier. À cet égard, l’utilisateur est tenu de s’assurer qu’à l’issue de chaque session il se déconnecte effectivement des services, en particulier lorsqu’il accède aux services à partir d’un ordinateur public.
          Toute perte, détournement ou utilisation non autorisée des identifiants d’un utilisateur et leurs conséquences relèvent de la seule responsabilité dudit utilisateur.
        </p>

        <h5>PROTECTION DES DONNÉES À CARACTÈRE PERSONNEL</h5>
        <p>Allô Doc s’engage à prendre toutes les mesures nécessaires afin de protéger toutes les données à caractère personnel susceptible de porter atteinte aux droits et libertés de l’utilisateur. </p>

        <h5>MENTIONS LÉGALES</h5>
        <p>Le service de Allô Doc est exploité et hébergé par Villa Santé dont le siège social est situé au 15, angles rue Metellus et Borno, Pétion-Ville.</p>
        </div>
      </div>

    </Layout>
  );
};

export default Terms;